@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


html,
body {
  padding: 0;
  margin: 0;
  font-family:Cinzel !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
.hover-sun{
  transition: all 0.5s;
}
.hover-sun:hover{
  transform: scale(1.2);
}